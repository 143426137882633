<template>
    <!-- 联系我们 -->
    <div class="contact">
        <topHeader ind="6"></topHeader>
        <div class="contact_t">
            <PageContent :title="'联系我们'" />
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import PageContent from '../comm/pageContent.vue'
    export default {
         components:{
            PageContent
        },
    }
</script>

<style scoped lang='less'>
.contact_t{
    min-height: 517px;
}
</style>